<template>
    <div>
        <Loading v-if="processing" />
        <div class="w-full relative z-10">
            <div class="menu-cover">
                <div class="absolute left-0 right-0 w-full flex flex-row left-menu">
                    <transition name="menu">
                        <div class="z-10 relative h-screen bg-black transition-all ease-in-out transform duration-500 overflow-y-scroll menu-height"
                            id="menu" v-if="showSideMenu">
                            <div class="absolute top-0 left-0 cursor-pointer w-full" @click="menuToggle()">
                                <div class="w-full flex justify-end px-2">
                                    <i class="block fas fa-times text-white text-2xl"></i>
                                </div>
                            </div>
                            <div class="pt-10 h-full flex flex-col justify-between" v-if="user">
                                <div v-if="!deleteaccount">
                                    <router-link class="block p-2 rounded-md m-2 rapbg text-base text-black"
                                        v-if="user.slug && user.type == 'artist'"
                                        :to="{ name: 'ProfileWithSlug', params: { slug: user.slug } }">
                                        My profile
                                        <p class="menu-details">Edit your profile</p>
                                    </router-link>
                                    <router-link class="block p-2 rounded-md m-2 rapbg text-base text-black"
                                        v-if="user.type == 'user'" :to="{
                                            name: 'CProfileWithSlug',
                                            params: { slug: user.slug },
                                        }">
                                        My profile
                                        <p class="menu-details">Edit your profile</p>
                                    </router-link>

                                    <router-link class="block p-2 rounded-md m-2 rapbg text-base text-black"
                                        :to="{ name: 'TopSongs' }">
                                        Top songs
                                        <p class="menu-details">Most popular songs</p>
                                    </router-link>
                                    <router-link class="block p-2 rounded-md m-2 rapbg text-base text-black"
                                        :to="{ name: 'VernonDavis' }">
                                        Vernon Davis
                                        <p class="menu-details">Our font designer</p>
                                    </router-link>
                                    <!-- <router-link
                  class="block p-2 rounded-md m-2 rapbg text-base text-black"
                  :to="{ name: 'VernonDavis' }"
                >
                  the blast app
                  <p class="menu-details">a new way to chat online</p>
                </router-link> -->
                                    <!-- <router-link
                  class="block p-2 rounded-md m-2 rapbg text-base text-black"
                  :to="{ name: 'FreeUniversity' }"
                >
                  Free University
                  <p class="menu-details">enroll in you</p>
                </router-link> -->
                                    <router-link class="block p-2 rounded-md m-2 rapbg text-base text-black"
                                        :to="{ name: 'Community' }">
                                        Community
                                        <p class="menu-details">Most active members</p>
                                    </router-link>

                                    <router-link class="block p-2 rounded-md m-2 rapbg text-base text-black"
                                        :to="{ name: 'FreeUniversity' }">
                                        Terms
                                        <p class="menu-details">View our rules</p>
                                    </router-link>

                                    <router-link class="block p-2 rounded-md m-2 rapbg text-base text-black"
                                        :to="{ name: 'AccountInfo' }" v-if="user.slug">
                                        Account info
                                        <p class="menu-details">View your account info</p>
                                    </router-link>

                                    <router-link class="block p-2 rounded-md m-2 rapbg text-base text-black"
                                        :to="{ name: user.rcode ? 'RcodeHistory' : 'GenerateRcode' }" v-if="user.slug">
                                        R-code
                                        <p class="menu-details u">Invite artists and earn cash</p>
                                    </router-link>

                                    <!-- <router-link class="block p-2 rounded-md m-2 rapbg text-base text-black" :to="{ name: 'GenerateRcode' }"
                  v-if="user.slug  || user.type">
                  Generate R-CODE
                  <p class="menu-details">create R-code</p>
                </router-link> -->

                                    <div class="block p-2 rounded-md m-2 rapbg text-base text-black"
                                        v-if="user.slug || user.type" @click="accountpopup = true">
                                        Delete account
                                        <p class="menu-details">It will be gone forever</p>
                                    </div>
                                    <!-- <router-link
                  class="block p-2 rounded-md m-2 rapbg text-base text-black"
                  v-if="!user.slug && !user.type"
                  :to="{ name: 'FreeUniversity' }"
                >
                  term & privacy
                  <p class="menu-details">learn our guideline</p>
                </router-link> -->
                                    <router-link class="block p-2 rounded-md m-2 rapbg text-base text-black"
                                        v-if="!user.slug && !user.type" :to="{ name: 'RapLandBasics' }">
                                        RapLand info
                                        <p class="menu-details">Learn how RapLand works</p>
                                    </router-link>
                                </div>
                                <div class="mx-2">
                                    <p class="text-sm text-white">Made with love by tba!</p>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>

            <transition name="bounce">
                <div class="absolute shadow-lg text-center top-0 right-0 left-0 m-auto w-full h-full bg-green-600 bg-opacity-50 z-50"
                    v-if="accountpopup">
                    <div
                        class="absolute top-10 shadow-lg text-center right-0 left-0 m-auto bg-white w-11/12 h-44 p-2 rounded-xl">
                        <p class="mt-4">Are you sure you want to delete account?</p>
                        <div class="lg:h-4/5 md:h-4/5 h-3/5 relative">
                            <button
                                class="w-20 absolute bottom-2 left-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                                @click="accountpopup = false">
                                Cancel
                            </button>
                            <button
                                class="w-20 absolute bottom-2 right-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                @click="deleteAccount">
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="bounce">
                <div class="absolute shadow-lg text-center top-0 right-0 left-0 m-auto w-full h-full bg-green-600 bg-opacity-50 z-50"
                    v-if="logoutpopup">
                    <div
                        class="absolute top-10 shadow-lg text-center right-0 left-0 m-auto bg-white w-11/12 h-44 p-2 rounded-xl">
                        <p class="mt-4">Are you sure you want to logout ?</p>
                        <div class="h-3/4 relative">
                            <button class="w-30 absolute bottom-2 left-2 rap-btn" @click="logoutpopup = false">
                                Cancel
                            </button>
                            <button class="w-30 absolute bottom-2 right-2 rap-green-btn text-black" @click="logout">
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
            <div :class="{
                'grid  pt-2 mx-2 md:h-15 flex items-center': true,
                'grid-cols-5': currentRouteName == 'CProfileWithSlug',
                'grid-cols-3': currentRouteName != 'CProfileWithSlug',
            }">
                <div v-if="currentRouteName == 'TopRappers'" class="lg:m-2 md:h-2/4 h-3/4 w-3/12 cursor-pointer"
                    @click="menuToggle()">
                    <img src="../../assets/image/menu.png" alt="" class="header-action-image" />
                </div>

                <div v-else :class="{
                    'lg:m-2 cursor-pointer': true,
                    'w-3/12': currentRouteName != 'CProfileWithSlug',
                    'col-span-1': currentRouteName == 'CProfileWithSlug',
                }" @click="goBack()">
                    <img v-if="this.$route.name != 'StripePayment'" src="../../assets/image/left-arrow.png" alt=""
                        class="header-action-image" />
                </div>

                <router-link v-if="currentRouteName != 'CProfileWithSlug'" :to="{ name: 'TopRappers' }"
                    class="lg:text-3xl md:text-3xl text-2xl justify-self-center font-boldcom logo text-right">
                    RapLand
                    <div class="text-xxs text-white leading-normal font-bold tracking-wider">Beta</div>
                </router-link>
                <div class="text-right text-gray-400 text-lg" v-if="currentRouteName == 'RapLandBasics'">
                    3-Steps
                </div>
                <div v-else-if="currentUser &&
                    currentUser.slug == $route.params.slug &&
                    currentRouteName == 'CProfileWithSlug'
                    " :class="{
                        'col-span-3 text-center': currentUser.member_name != '',
                        'justify-self-center': currentUser.member_name == '',
                    }">
                    <router-link :to="{ name: 'TopRappers' }" class="lg:text-xl md:text-2xl text-2xl nunito">
                        {{ currentUser.member_name }}
                    </router-link>
                </div>

                <div v-else-if="user &&
                    user.slug != $route.params.slug &&
                    currentRouteName == 'ProfileWithSlug'
                    " class="justify-self-end">
                    <router-link :to="{ name: 'ArtistSubmitComment', params: {slug: $route.params.slug} }" class="rapbg block rounded-full">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-10 h-10">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M4 12H20M12 4V20" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </g>
                        </svg>
                    </router-link>
                </div>

                <div class="text-right" v-else-if="user.public_id && currentRouteName != 'CProfileWithSlug'">
                    <span @click="onlogout" class="rap-btn cursor-pointer">
                        Sign out
                    </span>
                </div>
                <div class="text-right" v-else-if="currentRouteName != 'Login' &&
                    currentRouteName != 'Signup' &&
                    currentRouteName != 'CProfileWithSlug'
                    ">
                    <router-link :to="{ name: 'Signup' }" class="rap-btn cursor-pointer" v-if="!user.slug">
                        Sign up/in
                    </router-link>
                </div>
                <div class="text-right" v-else-if="currentRouteName == 'Login'">
                    <router-link :to="{ name: 'Signup' }" class="rap-btn cursor-pointer" v-if="!user.slug">
                        Sign up
                    </router-link>
                </div>
                <div class="text-right" v-else-if="currentRouteName == 'Signup'">
                    <router-link :to="{ name: 'Login' }" class="rap-btn cursor-pointer" v-if="!user.slug">
                        Sign in
                    </router-link>
                </div>
            </div>
            <div v-if="currentRouteName != 'VotingHistory' &&
                currentRouteName != 'PurchaseHistory'
                " :class="{
        'mx-3 mt-3 mb-5': true,
        'border-b-2 border-gray-300': currentRouteName == 'CProfileWithSlug',
        'border-b-2 border-black': currentRouteName != 'CProfileWithSlug',
    }"></div>
        </div>
    </div>
</template>

<script>
import API from "../../api/http";
import Loading from "../Shared/Loading";

export default {
    name: "Header",
    components: {
        Loading,
    },
    emits: ["deleteUserAccount"],
    data() {
        return {
            showSideMenu: false,
            account: false,
            accountpopup: false,
            logoutpopup: false,
            processing: false,
        };
    },
    watch: {
        $route() {
            this.showSideMenu = false;
            this.$emit("menuToggle", false);
            // this.menuToggle();
        },
    },
    computed: {
        user() {
            return this.$store.getters["User/user"];
        },
        currentUser() {
            return this.$store.getters["Cprofile/currentUser"][
                this.$route.params.slug
            ];
        },
        currentRouteName() {
            return this.$route.name;
        },
    },
    methods: {
        onlogout() {
            this.logoutpopup = true;
            this.processing = true;
        },
        chieldMenu() {
            this.account = !this.account;
            this.deleteaccount = !this.deleteaccount;
        },
        deleteAccount() {
            API.post("delete-profile", { _method: "DELETE" }).then((response) => {
                if (response.message == "Profile deleted successfully!") {
                    this.$emit("deleteUserAccount", this.user);
                    this.$store.dispatch("reset");
                    this.accountpopup = false;
                    this.showSideMenu = false;
                    this.$router.push({ name: "TopRappers" });
                }
            });
        },
        logout() {
            this.logoutpopup = false;
            API.logout().then(() => {
                this.processing = false;
                this.$router.push({ name: "Login" });
            });
        },
        goBack() {
            if (this.$route.name == "Signup") {
                this.$store.commit("User/SET_SIGNUP_STEP", true);
            } else {
                this.$router.go(-1);
            }
        },
        menuToggle() {
            this.showSideMenu = !this.showSideMenu;
            this.deleteaccount = false;
            this.account = false;
            this.$emit("menuToggle", this.showSideMenu);
        },
    },
};
</script>
